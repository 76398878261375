import React, { useCallback, useRef } from 'react';
import cx from 'classnames';

import { StudioKeys, SortColumnFunction, SortOrder } from 'types';
import { Grid } from 'components/Grid';
import { GridItem } from 'components/GridItem';
import { TableHeaderItem } from 'components/TableHeaderItem';

import * as styles from './index.module.scss';
import { useStickyHeader } from 'hooks/useStickyHeader';

interface TableHeaderProps {
  selectedColumn: StudioKeys;
  selectedOrder: SortOrder;
  sortColumn: SortColumnFunction;
}

export const TableHeader: React.FC<TableHeaderProps> = ({
  selectedColumn,
  selectedOrder,
  sortColumn,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const isSticky = useStickyHeader(headerRef);
  const onClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();

      const newColumn = event.currentTarget.getAttribute(
        'data-column'
      ) as StudioKeys;

      sortColumn(newColumn, true);
    },
    [sortColumn]
  );

  return (
    <div className={styles.headerWrapper} ref={headerRef}>
      <Grid className={cx({ [styles.headerFixed]: isSticky })}>
        <GridItem className={styles.th} colS="11" colM="11" colL="12">
          <TableHeaderItem
            onClick={onClick}
            selectedOrder={
              selectedColumn === 'name' ? selectedOrder : undefined
            }
            title="Name"
          />
        </GridItem>

        <GridItem className={styles.th} colL="22">
          <TableHeaderItem
            onClick={onClick}
            selectedOrder={
              selectedColumn === 'field' ? selectedOrder : undefined
            }
            title="Field"
          />
        </GridItem>

        <GridItem className={styles.th} colS="22" colM="22" colL="33">
          <TableHeaderItem
            onClick={onClick}
            selectedOrder={
              selectedColumn === 'country' ? selectedOrder : undefined
            }
            title="Country"
          />
        </GridItem>
      </Grid>
    </div>
  );
};
