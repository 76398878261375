import React from 'react';

import { Studio } from 'types';
import { TableBodyItem } from 'components/TableBodyItem';

import * as styles from './index.module.scss';

interface TableBodyProps {
  isLoading: boolean;
  studios: Studio[];
}

export const TableBody: React.FC<TableBodyProps> = ({ isLoading, studios }) => (
  <div className={styles.wrapper}>
    {isLoading && <div className={styles.loading}></div>}
    {studios.map((studio) => {
      const { abbr, country, field, name } = studio;
      return (
        <TableBodyItem
          key={`${name} - ${field} - ${country}`}
          studio={studio}
        />
      );
    })}
  </div>
);
