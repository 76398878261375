import React from 'react';

import { useSortStudios } from 'hooks/useSortStudios';
import { Studio } from 'types';
import { TableHeader } from 'components/TableHeader';
import { TableBody } from 'components/TableBody';

interface TableProps {
  isLoading: boolean;
  studios: Studio[];
}

export const Table: React.FC<TableProps> = ({ isLoading, studios }) => {
  const { column, order, sortColumn, sortedStudios } = useSortStudios(studios);

  return (
    <>
      <TableHeader
        selectedColumn={column}
        selectedOrder={order}
        sortColumn={sortColumn}
      />
      <TableBody isLoading={isLoading} studios={sortedStudios} />
    </>
  );
};
