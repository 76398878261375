import { useState, useCallback } from 'react';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

import { Studio, StudioKeys, SortColumnFunction, SortOrder } from 'types';

export const useSortStudios = (
  studios: Studio[]
): {
  column: StudioKeys;
  order: SortOrder;
  sortColumn: SortColumnFunction;
  sortedStudios: Studio[];
} => {
  const [column, setColumn] = useState<StudioKeys>('name');
  const [order, setOrder] = useState<SortOrder>(SortOrder.asc);

  const sortColumn = useCallback<SortColumnFunction>(
    (selectedColumn, trackEvent) => {
      let sortOrder: SortOrder;

      if (selectedColumn !== column) {
        setColumn(selectedColumn);
        sortOrder = SortOrder.asc;
      } else {
        sortOrder = order === SortOrder.asc ? SortOrder.desc : SortOrder.asc;
      }

      setOrder(sortOrder);

      if (trackEvent) {
        const sortOrderText =
          sortOrder === SortOrder.asc ? 'ascending' : 'descending';

        trackCustomEvent({
          category: 'Table Header',
          action: 'Sort Columns',
          label: `${selectedColumn} - ${sortOrderText}`,
        });
      }
    },
    [column, order]
  );

  return {
    column,
    order,
    sortColumn,
    sortedStudios: [...studios].sort((a, b) => {
      const aValue = a[column].toLowerCase();
      const bValue = b[column].toLowerCase();

      if (order === SortOrder.asc) {
        return aValue > bValue ? 1 : -1;
      }

      return aValue < bValue ? 1 : -1;
    }),
  };
};
