import React, { useCallback } from 'react';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import twemoji from 'twemoji';

import { Studio } from 'types';
import { Grid } from 'components/Grid';

import * as styles from './index.module.scss';

interface TableBodyItemProps {
  studio: Studio;
}

export const TableBodyItem: React.FC<TableBodyItemProps> = ({ studio }) => {
  const { abbr, country, field, flag, href, name } = studio;

  const trackClick = useCallback(() => {
    trackCustomEvent({
      category: 'Studios List',
      action: 'Open Studio',
      label: `${name} - ${field} - ${country}`,
    });
  }, [country, field, name]);

  const linkSuffix = '?ref=designstudios-cc';
  const link = href.endsWith('/')
    ? `${href}${linkSuffix}`
    : `${href}/${linkSuffix}`;

  return (
    <a className={styles.link} href={link} onClick={trackClick} target="_blank">
      <Grid className={styles.tr}>
        <div className={styles.td}>{name}</div>
        <div className={styles.td}>{field}</div>
        <div className={styles.td}>
          <span className={styles.countryAbbr}>{abbr}</span>
          <span className={styles.countryName}>{country}</span>
          <span
            className={styles.countryFlag}
            dangerouslySetInnerHTML={{
              __html: twemoji.parse(flag, {
                className: styles.countryFlagImage,
                ext: '.svg',
                folder: 'svg',
              }),
            }}
          />
        </div>
      </Grid>
    </a>
  );
};
