export interface Studio {
  abbr: string;
  country: string;
  field: string;
  flag: string;
  href: string;
  name: string;
}

export type StudioKeys = keyof Studio;

export type SortColumnFunction = (
  selectedColumn: StudioKeys,
  trackEvent?: boolean
) => void;

export enum SortOrder {
  asc,
  desc,
}
