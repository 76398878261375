import React from 'react';
import cx from 'classnames';

import { SortOrder } from 'types';

import * as styles from './index.module.scss';

interface TableHeaderItemProps {
  onClick: (event: React.MouseEvent) => void;
  selectedOrder: SortOrder | undefined;
  title: string;
}

export const TableHeaderItem: React.FC<TableHeaderItemProps> = ({
  onClick,
  selectedOrder,
  title,
}) => {
  return (
    <a
      className={styles.button}
      data-column={title.toLowerCase()}
      href="#"
      onClick={onClick}
    >
      {title}
      <span
        className={cx(styles.arrow, {
          [styles.arrowSelected]: selectedOrder === SortOrder.asc,
        })}
      >
        ↓
      </span>
      <span
        className={cx(styles.arrow, {
          [styles.arrowSelected]: selectedOrder === SortOrder.desc,
        })}
      >
        ↑
      </span>
    </a>
  );
};
