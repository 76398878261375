import React, { useEffect, useRef } from 'react';
import fitty from 'fitty';

import * as styles from './index.module.scss';

export const Header: React.FC = () => {
  const title = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (title.current) {
      fitty(title.current);
    }
  }, []);

  return (
    <header className={styles.header}>
      <h1 className={styles.title} ref={title}>
        Design Studios
      </h1>
    </header>
  );
};
