import React from 'react';

import { useSiteData } from 'hooks/useSiteData';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { HeadSeo } from 'components/HeadSeo';

import 'styles/index.scss';
import { Table } from 'components/Table';

const IndexPage: React.FC = () => {
  const { isLoading, studios } = useSiteData();

  return (
    <>
      <HeadSeo />
      <Header />
      <Table isLoading={isLoading} studios={studios} />
      <Footer />
    </>
  );
};

export default IndexPage;
